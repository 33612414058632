<template lang="pug">
  div.row
    transition(name="fade")
      div.overlay(v-if="!save_ready")
    //- Header
    div.article-edit-header
      div.px-2
        p.fs-5.mb-0 {{ article_id }}:{{ article.content.title }}
        //- button.btn.btn-primary(type="button", @click="save") Save      
        div.d-flex
          div.me-4 文字数:{{ total_count }}
          div.d-flex.align-items-center(v-if="!save_ready")
            div.spinner-border.spinner-border-sm(role="status")
            strong.ms-2 Initializing...
          template(v-else)
            div.d-flex.align-items-center(v-if="saving")
              div.spinner-border.spinner-border-sm(role="status")
              strong.ms-2 Saving...
            div(v-else)
              strong Saved!
      hr.mt-2.mb-0
    //- Title, CoverImage, Description, Outline
    div.col(style="margin-top: 70px;")
      div.editor
        //- Title
        div.basic.title
          div#article-title(contenteditable, placeholder="Title", @input="save_title")
          span.word-count {{ article.content.title.length }}
        //- CoverImage
        div.basic.feature-image.mt-3
          div.d-flex.justify-content-center(v-if="feature_image_uploading")
            div.spinner-border.spinner-border-sm(role="status")
              span.visually-hidden Uploading...
          template(v-else)
            div.image(v-if="article.content.feature_image")
              img(:src="article.content.feature_image")
              button.ce-settings__button.ce-settings__button--delete(type="button", :class="del_feature_image_confirm? 'ce-settings__button--confirm':''", @click="del_feature_image_confirm? (del_feature_image()):(del_feature_image_confirm=true);", @blur="del_feature_image_confirm=false;")
                <svg class="icon icon--cross" width="12px" height="12px"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#cross"></use></svg>
            template(v-else)
              div.d-grid
                button.btn.btn-lg.btn-outline-secondary(type="button", @click="select_feature_image") Select Feature Image
                input#feature-image-input(type="file", name="image", accept="image/*", style="display:none;", @change="submit_feature_image")
              form
                div.input-group.mt-2
                  input.form-control(type="text", v-model="feature_image_url", placeholder="Image's URL", aria-label="Image's URL", aria-describedby="feature-image-by-url-btn")
                  button.btn.btn-outline-secondary#feature-image-by-url-btn(type="submit", @click="upload_feature_image_by_url") Upload
        //- Description
        div.basic.lead.mt-3
          div#lead-editor
          span.word-count {{ lead_length }}
        //- Outlines
        div.basic.outline.mt-3
          div
            span 目次（自動生成）
          ul
            li(v-for="h in headers", :key="h.id", :data-header-level="h.data.level")
              span {{ h.data.text }}

        div.mt-4#main-editor
    div.col-blog-sidebar.sidebar(style="margin-top: 70px;")
      div
        div
          p Published: {{ article.published_at? moment.unix(article.published_at).format('YYYY/MM/DD HH:mm'):'' }}
          p(v-if="article.updated_at") Updated: {{ article.updated_at? moment.unix(article.updated_at).format('YYYY/MM/DD HH:mm'):'' }}
        div.mt-3
          button.btn.btn-outline-success(type="button", v-if="!article.published", @click="publish") Publish
          button.btn.btn-outline-success(type="button", v-if="article.published && article.draft", @click="publish") Update
        div.mt-3
          label Slug(URL)
          input.form-control(type="text", v-model="article.slug", :disabled="article.published", @change="save_basic")
          div.form-text 半角英数字のみ
        div.form-check.mt-3
          input.form-check-input#featured-chk(type="checkbox", v-model="article.featured", @change="save_basic")
          label.form-check-label(for="featured-chk") Featured
        div.mt-3
          label Category
          vSelect(:options="categories", label="name", v-model="article.category_id", :reduce="cat=>cat.id", @option:selected="save_category", @option:deselected="save_category")
          div.form-text.text-danger(v-if="!article.categor_id") カテゴリー指定は必須です
        div.mt-3
          label Tag
          vSelect(:options="tags", label="name", multiple, v-model="article.tags", :reduce="tag=>tag.id", @option:selected="save_tags", @option:deselected="save_tags")
        div.mt-3(v-if="original_related_article_options.length")
          label Related Article
          vSelect(:key="related_article_reset", :options="related_article_options", label="title", :reduce="a=>a.id", @option:selected="selectRelated", placeholder="タイトルを入力して検索")
          div.card.related-article.mt-1(v-for="ra_id in article.relates")
            div.row.g-0
              div.col-4
                img(:src="original_related_article_options.filter(e=>e.id===ra_id)[0].image", alt="...")
              div.col
                div.card-body
                  p.card-text {{ original_related_article_options.filter(e=>e.id===ra_id)[0].title }}
              div.col-auto.d-flex.align-items-center
                  button.btn-close(type="button", @click="delRelated(ra_id)")

</template>

<script>
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import NestedList from '@editorjs/nested-list';
import Table from '@editorjs/table';
import RawTool from '@editorjs/raw';
import Delimiter from '@editorjs/delimiter';
import Undo from 'editorjs-undo';
import CodeBox from '@bomdi/codebox';
import Quote from '@editorjs/quote';
import Embed from '@editorjs/embed';

import Marker from '@editorjs/marker';
import Underline from '@editorjs/underline';
import InlineCode from '@editorjs/inline-code';
import createGenericInlineTool from 'editorjs-inline-tool';

import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
import moment from 'moment';

import { debounce } from 'lodash';

export default {
  name:"BlogArticle",
  components:{
    vSelect
  },
  data (){
    return {
      moment:moment,
      article_id:0,
      editorjs:null,
      lead_editorjs:null,
      headers:[],

      feature_image_url:"",
      feature_image_uploading:false,

      save_ready:false,
      saving:false,

      categories:[],
      tags:[],

      article:{
        slug:"",
        content:{
          title:"",
          feature_image:"",
          lead:"",
          description:{},
          blocks:{}
        },
        
        featured:false,
        published:true,
        draft:true,
        
        published_at:1637820786,
        updated_at:null,
        
        tags:[],
        category_id:null,
        relates:[]
      },
      original_related_article_options:[],
      related_article_options:[],
      related_article_reset:1,
      del_feature_image_confirm:false,

      total_count:0,
      lead_length:0,
    }
  },
  async created (){
    this.article_id = Number(this.$route.query.id);
  },
  async mounted (){
    await this.get_article();
    this.init_editorjs_lead();
    this.init_editorjs();
    await this.get_tags();
    await this.get_categories();
    await this.get_articles();

    this.save_throttled = debounce(this.save, 3000);
  },
  methods: {
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get_categories (){
      try{
        let res = await this.axios.get("/api/blog/category");
        this.categories = res.data;
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async get_tags (){
      try{
        let res = await this.axios.get("/api/blog/tag");
        this.tags = res.data;
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async get_article(){
      try{
        let res = await this.axios.get("/api/blog/article?id="+this.article_id);
        this.article = res.data;
        document.getElementById("article-title").innerText = this.article.content.title;
        this.headers = this.article.content.blocks.blocks?.filter(e=>e.type=="header");
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    init_editorjs_lead (){
      // let self = this;
      // this.save_ready = false;
      if (this.editorjs_lead)
        this.editorjs_lead.destroy();
      this.editorjs_lead = new EditorJS({
        data:this.article.content.description,
        holder: 'lead-editor',
        placeholder: 'Paragraph',
        tools: {
          Marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
          },
          underline: Underline,
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M',
          },
          del: {
            class: createGenericInlineTool({
              sanitize: {
                del: {},
              },
              shortcut: 'CMD+D',
              tagName: 'DEL',
              toolboxIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" style="fill:none!important;" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>',
            }),
          },
          ins: {
            class: createGenericInlineTool({
              sanitize: {
                ins: {},
              },
              shortcut: 'CMD+I',
              tagName: 'INS',
              toolboxIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" style="fill:none!important;" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>',
            }),
          }
        },
        onChange: this.save_lead,
        onReady: () => {
          this.lead_length = document.getElementById("lead-editor").innerText.replace(/\n/g, "").length;
        },
        logLevel: 'WARN'
      });
    },
    init_editorjs (){
      let self = this;
      this.save_ready = false;
      if (this.editorjs)
        this.editorjs.destroy();
      this.editorjs = new EditorJS({
        data:this.article.content.blocks,
        holder: 'main-editor',
        placeholder: 'Paragraph',
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+H',
            config: {
              placeholder: 'Header',
              levels: [2, 3, 4],
              defaultLevel: 2
            }
          },
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: '/api/blog/image', // Your backend file uploader endpoint
                byUrl: '/api/blog/image', // Your endpoint that provides uploading by Url
              }
            }
          },
          list: {
            class: NestedList,
            inlineToolbar: true,
          },
          table: Table,
          raw:RawTool,
          delimiter: Delimiter,
          codeBox: {
            class: CodeBox,
            config: {
              themeURL: 'https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.3.1/build/styles/github-dark-dimmed.min.css', // Optional
              // themeName: 'atom-one-dark', // Optional
              // useDefaultTheme: 'light' // Optional. This also determines the background color of the language select drop-down
            }
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
            shortcut: 'CMD+SHIFT+O',
            config: {
              quotePlaceholder: 'Enter a quote',
              captionPlaceholder: 'Quote\'s author',
            },
          },
          embed: {
            class: Embed,
            config: {
              services: {
                youtube: true
              }
            }
          },
          Marker: {
            class: Marker,
            shortcut: 'CMD+SHIFT+M',
          },
          underline: Underline,
          inlineCode: {
            class: InlineCode,
            shortcut: 'CMD+SHIFT+M',
          },
          del: {
            class: createGenericInlineTool({
              sanitize: {
                del: {},
              },
              shortcut: 'CMD+D',
              tagName: 'DEL',
              toolboxIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" style="fill:none!important;" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-delete"><path d="M21 4H8l-7 8 7 8h13a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"></path><line x1="18" y1="9" x2="12" y2="15"></line><line x1="12" y1="9" x2="18" y2="15"></line></svg>',
            }),
          },
          ins: {
            class: createGenericInlineTool({
              sanitize: {
                ins: {},
              },
              shortcut: 'CMD+I',
              tagName: 'INS',
              toolboxIcon:
                '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" style="fill:none!important;" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="12" y1="8" x2="12" y2="16"></line><line x1="8" y1="12" x2="16" y2="12"></line></svg>',
            }),
          }
        },
        onChange: this.onBlocksChange,
        onReady: () => {
          const undo = new Undo({ editor:this.editorjs });
          undo.initialize(this.article.content.blocks);
          setTimeout(e=>e.save_ready=true, 1000, self);
          this.word_count();
        },
        logLevel: 'WARN'
      });
    },
    async get_articles (){
      try {
        this.original_related_article_options = (await this.axios.get("/api/blog/articles?title")).data.filter(e=>e.id!==this.article_id);
        this.related_article_options = this.original_related_article_options.filter(e=>!this.article.relates.includes(e.id));
      }
      catch(e){
        console.error(e);
        this.$swal("Error", "関連記事情報の読み込みに失敗しました。", "Error");
      }
    },
    async selectRelated (e){
      console.log(e);
      this.article.relates.push(e.id);
      this.related_article_options = this.original_related_article_options.filter(e=>!this.article.relates.includes(e.id));
      this.related_article_reset++;
      await this.save_relates();
    },
    async delRelated (ra_id){
      this.article.relates.splice(this.article.relates.indexOf(ra_id),1);
      this.related_article_options = this.original_related_article_options.filter(e=>!this.article.relates.includes(e.id));
      await this.save_relates();
    },
    select_feature_image (){
      document.getElementById("feature-image-input").click();
    },
    async submit_feature_image(e){
      this.feature_image_uploading = true;
      const data = new FormData();
      data.append("image", e.target.files[0]);
      try {
        let res = await this.axios.post("/api/blog/image", data, {headers:{'content-type': 'multipart/form-data'}});
        this.article.content.feature_image = res.data.file.url;
        await this.save_content();
        this.feature_image_uploading = false;
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async del_feature_image(){
      this.article.content.feature_image='';
      await this.save_content();
    },
    async upload_feature_image_by_url(e){
      e.preventDefault();
      this.feature_image_uploading = true;
      try {
        let res = await this.axios.post("/api/blog/image", { url:this.feature_image_url });
        this.article.content.feature_image = res.data.file.url;
        this.feature_image_url = "";
        await this.save_content();
        this.feature_image_uploading = false;
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async save_title (e){
      this.article.content.title = e.target.innerText;
      await this.save_content();
    },
    async save_lead (e){
      this.article.content.description = await this.editorjs_lead.save();
      this.lead_length = document.getElementById("lead-editor").innerText.replace(/\n/g, "").length;
      await this.save_content();
    },
    async save_basic (){
      let data = {
        id:this.article_id,
        slug:this.article.slug,
        featured:this.article.featured,
      }
      this.saving = true;
      await this.save_throttled(data);
    },
    async save_content (){
      this.article.content.blocks = await this.editorjs.save();
      let data = {
        id:this.article_id,
        slug:this.article.slug,
        featured:this.article.featured,
        content:this.article.content
      }
      this.saving = true;
      await this.save_throttled(data);
    },
    async save_category (){
      let data = {
        id:this.article_id,
        slug:this.article.slug,
        featured:this.article.featured,
        category_id:this.article.category_id
      }
      this.saving = true;
      await this.save_throttled(data);
    },
    async save_tags (){
      let data = {
        id:this.article_id,
        slug:this.article.slug,
        featured:this.article.featured,
        tags:this.article.tags
      }
      this.saving = true;
      await this.save_throttled(data);
    },
    async save_relates (){
      let data = {
        id:this.article_id,
        slug:this.article.slug,
        featured:this.article.featured,
        relates:this.article.relates
      }
      this.saving = true;
      await this.save_throttled(data);
    },
    word_count (){
      this.total_count = this.article.content.title.replace(/\n/g, "").length + this.lead_length + document.getElementById("main-editor").innerText.replace(/\n/g, "").length;
    },
    async save (data){
      this.word_count();
      if (!this.save_ready)
        return;
      try{
        let res = this.axios.post("/api/blog/article", data);
        this.article.draft = true;
        this.saving=false;
        this.headers = this.article.content.blocks.blocks.filter(e=>e.type=="header");
      }
      catch(e){
        console.log(e);
        let val = await this.$swal("Error","保存できませんでした","error", { buttons:["閉じる","もう一度保存"] });
        if (val)
          await this.save(data);
      }
    },
    async onBlocksChange (e){
      await this.save_content();
    },
    async publish (){
      try{
        let res = await this.axios.post("/api/blog/article", { "id":this.article_id, "publish":true });
        this.$swal("","","success");
        await this.get_article();
        this.init_editorjs();
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  background-color: black;
  opacity: 0.65;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.article-edit-header {
  width: 100%;
  position: fixed;
  font-size: 1rem;
  background-color: white;
  z-index: 1001;
  top:0;
  padding-top: 50px;
  padding-left: 0;
}

.editor ::v-deep {
  $width: 750px;
  border: solid 10px #EBF4F9;
  padding: 20px;
  font-size: 1rem;
  line-height: 2rem;

  .basic, hr.basic {
    max-width: $width;
    margin-left:auto;
    margin-right:auto;
    &.title, &.lead {
      position: relative;
      .form-control {
        border: none;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
      }
      .word-count {
        position: absolute;
        left: calc(100% + 5px);
        bottom: 0px;
        color: #6c757d;
        font-size: 14px;
      }
    }
    &.title input {
      font-size: 24px;
    }
    &.lead, &.title {
      div{
        outline: none!important;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &:focus {
          border-color: #86b7fe;
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
        }
        &:empty:before{
          color: #707684;
          content: attr(placeholder);
          pointer-events: none;
          display: block; /* For Firefox */
        }
      }
    }
    &.title {
      div{
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.2;
      }
    }
    &.lead {
      div{
        font-size: 1rem;
        font-weight: normal;
        line-height: 2rem;
      }
    }
    &.feature-image {
      .image {
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        svg {
          fill: currentColor;
        }
        .ce-settings__button.ce-settings__button--delete {
          position: absolute;
          left: calc(100% + 5px);
          top: 50%;
          transform: translateY(-50%);
          border-radius: 3px;
        }
      }

      button {
        border-radius: 0;
      }
    } 
    &.outline {
      margin-top: 2rem;
      background: #f9f9f9;
      border: 1px solid #aaa;
      width: 100%;
      padding: 1rem;
      button {
        margin-left: 1rem;
        border: none;
        background-color: transparent;
        text-decoration: underline;
        padding: 1px 6px;
        vertical-align: unset;
      }
      ul {
        // background-color: rgba(0,0,0,.05);
        padding-top: 0.75rem;
        margin-bottom: 0;
        padding-left: 0;
        // padding-bottom: 0.75rem;
        list-style: none;
        li {
          margin-bottom: 0;
          a {
            color: inherit;
            text-decoration: none;
            &:hover, &:focus {
              text-decoration: underline;
            }
          }
        }
        li[data-header-level="2"] {
          font-size: 1rem;
          font-weight: 700;
          margin-top: .5rem;
          &::before {
            content: "・"
          }
        }
        li[data-header-level="3"] {
          font-size: .9rem;
          margin-left: 2rem;
          margin-top: .25rem;
        }
        li[data-header-level="4"] {
          font-size: .8rem;
          margin-left: 4rem;
          margin-top: .1rem;
        }
      }
    }

  }

    @media (min-width: 651px) {
      .codex-editor--narrow .codex-editor__redactor {
        margin-right: 0!important;
      }
    }

  .ce-toolbar {
    display: block!important;
  }

  .ce-toolbar__plus {
    left: -90px!important;
  }
  .ce-toolbar__actions {
    right: -80px!important;
    opacity: 1!important;
  }

  .ce-block__content {
    max-width: $width!important;
  }

  .ce-paragraph {
    font-size: 1rem;
    line-height: 2rem;
  }

  .ce-paragraph, .ce-header  {
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:focus {
      border-color: #86b7fe;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
    }
  }

  h2.ce-header {
    background-color: #3D5B78;
    color: white;
    padding: 1rem;
    margin: 45px auto 25px;
    font-size: 1.5rem;
  }
  h3.ce-header {
    color: #3D5B78;
    border-bottom: solid 3px #3D5B78;
    padding: 0 0 0.25rem 0;
    margin: 35px auto 20px;
    font-weight: bold;
    font-size: 1.25rem;
  }
  h4.ce-header {
    font-size: 1.1rem;
    color: #3D5B78;
    padding: 0 0 0.25rem 0;
    margin: 25px auto 15px;
    font-weight: bold;
    text-decoration: underline;
    text-underline-offset: 0.25rem;
    text-decoration-thickness: 2px;
  }

  .cdx-quote__text {
    min-height: unset;
    margin-bottom: 5px;
  }
  .cdx-quote__caption {
    padding: 0.5rem!important;
  }
  blockquote.cdx-block.cdx-quote::before {
    background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%233D5B78%22%20class%3D%22bi%20bi-quote%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M12%2012a1%201%200%200%200%201-1V8.558a1%201%200%200%200-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579%200-1.085.124-1.52.372a3.322%203.322%200%200%200-1.085.992%204.92%204.92%200%200%200-.62%201.458A7.712%207.712%200%200%200%209%207.558V11a1%201%200%200%200%201%201h2Zm-6%200a1%201%200%200%200%201-1V8.558a1%201%200%200%200-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579%200-1.085.124-1.52.372a3.322%203.322%200%200%200-1.085.992%204.92%204.92%200%200%200-.62%201.458A7.712%207.712%200%200%200%203%207.558V11a1%201%200%200%200%201%201h2Z%22%2F%3E%3C%2Fsvg%3E');
    width: 30px;
    height: 30px;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0.4em;
    left: 0;
  }
  blockquote.cdx-block.cdx-quote::after {
    background: url('data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%233D5B78%22%20class%3D%22bi%20bi-quote%22%20viewBox%3D%220%200%2016%2016%22%3E%20%3Cpath%20d%3D%22M12%2012a1%201%200%200%200%201-1V8.558a1%201%200%200%200-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579%200-1.085.124-1.52.372a3.322%203.322%200%200%200-1.085.992%204.92%204.92%200%200%200-.62%201.458A7.712%207.712%200%200%200%209%207.558V11a1%201%200%200%200%201%201h2Zm-6%200a1%201%200%200%200%201-1V8.558a1%201%200%200%200-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579%200-1.085.124-1.52.372a3.322%203.322%200%200%200-1.085.992%204.92%204.92%200%200%200-.62%201.458A7.712%207.712%200%200%200%203%207.558V11a1%201%200%200%200%201%201h2Z%22%2F%3E%3C%2Fsvg%3E');
    width: 30px;
    height: 30px;
    content: "";
    background-size: cover;
    position: absolute;
    top: 0.3em;
    right: 0;
    transform: rotate(180deg);
  }
  .cdx-quote .cdx-input {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 1.4rem 1.8rem 0 1.8rem;
    background: #F7F7F7;
  }


}

#lead-editor ::v-deep {
  .ce-toolbar {
    display: none!important;
  }
  .codex-editor__redactor {
    padding-bottom: 0!important;
  }
}

.col-blog-sidebar {
  flex: 0 0 auto;
  width: 250px;
}

.sidebar {
  border-left: solid 1px lightgray;
  font-size: 16px;
  min-height: 100%;
  // padding: .5rem .75rem;
  input[type=checkbox] {
    transform: scale(1.5) translateX(3px);
    margin-right: 1rem;
  }
  .related-article {
    border-radius: 0;
    .row {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1;
    }
    .card-body {
      padding: .25rem;
      font-size: 13px
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style lang="scss">
$vs-component-line-height: 1.6;
@import "vue-select/src/scss/vue-select.scss";

</style>

<style lang="scss">
pre.codeBoxHolder {
  overflow: unset!important;
}
.codeBoxHolder {
  .codeBoxTextArea {
    overflow-x: auto!important;
  }
}
</style>