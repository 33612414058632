<template lang="pug">
  div.row
    div.col-12
      h1.h4 Blog
    div.col-12
      a.btn.btn-primary(data-bs-toggle="collapse", href="#category-tag-collapse", role="button", aria-expanded="false", aria-controls="category-tag-collapse") Category&amp;Tag
      div.row.collapse#category-tag-collapse
        div.col-12
          h2.h5 Category
          table.table.table-sm.category-table
            thead
              tr
                th.id ID
                th.name Name
                th.slug Slug
                th.desc Description
            tbody
              tr(v-for="cat in categories", :key="cat.id")
                td.id {{ cat.id }}
                td {{ cat.name }}
                td {{ cat.slug }}
                td {{ cat.desc }}
              tr
                td.id
                  button.btn.btn-sm.btn-outline-primary(type="button", @click="create_new_category", :disabled="new_category.name.length===0 || new_category.slug.length===0 || new_category.description.length===0") 追加
                td
                  input.form-control.form-control-sm(type="text", v-model="new_category.name")
                td
                  input.form-control.form-control-sm(type="text", v-model="new_category.slug")
                td
                  textarea.form-control.form-control-sm(rows="1", v-model="new_category.description")
        div.col-12
          h2.h5 Tag
          table.table.table-sm.tag-table
            thead
              tr
                th.id ID
                th.name Name
                th.slug Slug
                th.desc Description
            tbody
              tr(v-for="tag in tags", :key="tag.id")
                td.id {{ tag.id }}
                td {{ tag.name }}
                td {{ tag.slug }}
                td {{ tag.desc }}
              tr
                td.id
                  button.btn.btn-sm.btn-outline-primary(type="button", @click="create_new_tag", :disabled="new_tag.name.length===0 || new_tag.slug.length===0 || new_tag.description.length===0") 追加
                td
                  input.form-control.form-control-sm(type="text", v-model="new_tag.name")
                td
                  input.form-control.form-control-sm(type="text", v-model="new_tag.slug")
                td
                  textarea.form-control.form-control-sm(rows="1", v-model="new_tag.description")
                
    div.col-12.mt-3
      h2.h5.d-inline-block Article
      button.btn.btn-outline-primary.ms-2.mb-2(type="button" , @click="create_article") 新規作成
    div.col-12
      VueGoodTable.articles(:columns="columns", :rows="articles", :pagination-options="pagination_options", styleClass="vgt-table striped condensed article-table", :sort-options="sort_options")
        //- Header
        template(slot="table-column", slot-scope="props")
          span(v-if="props.column.field == 'featured'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/></svg>
          span(v-else-if="props.column.field == 'at'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16"><path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/><path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/></svg>
          span(v-else-if="props.column.field == 'rank'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-award" viewBox="0 0 16 16"><path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68L9.669.864zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702 1.509.229z"/><path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"/></svg>
          span(v-else-if="props.column.field == 'tags'")
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tags" viewBox="0 0 16 16"><path d="M3 2v4.586l7 7L14.586 9l-7-7H3zM2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2z"/><path d="M5.5 5a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1zm0 1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM1 7.086a1 1 0 0 0 .293.707L8.75 15.25l-.043.043a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 0 7.586V3a1 1 0 0 1 1-1v5.086z"/></svg>
          span(v-else, style="font-size: 14px;")
            | {{ props.column.label }}
        //- Row
        template(slot="table-row", slot-scope="props")
          span(v-if="props.column.field == 'published'")
            <svg v-if="props.row.published" data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path data-v-080e764a="" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline data-v-080e764a="" points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'title'")
            router-link(:to="{ path:'/blog/article', query:{ id:props.row.id } }", style="font-size:14px;") {{ props.row.title? props.row.title:"untitled" }}
          span(v-else-if="props.column.field == 'featured'")
            <svg v-if="props.row.featured" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star" viewBox="0 0 16 16"><path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/></svg>
            //- button.featured-button(type="button", @click="update_featured($event, props.row.id)")
              //- <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill" viewBox="0 0 16 16"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/></svg>
          span(v-else-if="props.column.field == 'draft'")
            <svg v-if="props.row.draft"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16"><path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/><path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/></svg>
          div(v-else-if="props.column.field == 'category'")
            span.category {{ category_dict[props.row.category_id] }}
          div(v-else-if="props.column.field == 'tags'")
            span.tag(v-for="tag_id in props.row.tags") {{ tag_dict[tag_id] }}
          span(v-else-if="props.column.field == 'at'", style="font-size:14px;")
            <svg v-if="props.row.updated_at" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise me-1" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/><path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/></svg>
            | {{ props.row.updated_at? moment.unix(props.row.updated_at).format('YYYY/MM/DD'):(props.row.published_at? moment.unix(props.row.published_at).format('YYYY/MM/DD'):'') }}
          div(v-else-if="props.column.field == 'rank'")
            span {{ props.row.rank.rank }}
            span(style="font-size: 10px;") ({{ props.row.rank.count }})
          div(v-else-if="props.column.field == 'copy'")
            button.btn.btn-sm.btn-link(type="button", @click="duplicate(props.row.id)") 複製
            button.btn.btn-sm.btn-link(type="button", @click="delete_article(props.row)") 削除
          span(v-else)
            | {{ props.formattedRow[props.column.field] }}

</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import moment from 'moment';

export default {
  name:"BlogTop",
  components:{
    VueGoodTable
  },
  data (){
    return {
      moment:moment,
      categories:[],
      new_category:{
        name:"",
        slug:"",
        description:""
      },
      tags:[],
      new_tag:{
        name:"",
        slug:"",
        description:""
      },
      articles:[],

      pagination_options: {
        enabled: true,
        perPage: 20,
        perPageDropdown: [20, 50, 100]
      },
      sort_options:{
        enabled:true,
        initialSortBy:{field:'id', type:'desc'}
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '公開',
          field: 'published',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '編集',
          field: 'draft',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Featured',
          field: 'featured',
          width: '60px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Title',
          field: 'title',
          sortable: false,
        },
        {
          label: 'Category',
          field: 'category',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Tag',
          field: 'tags',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Published At',
          field: 'at',
          width: '110px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortFn: this.at_sortFn
        },
        {
          label: 'Rank',
          field: 'rank',
          width: '65px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortFn: this.rank_sortFn
        },
        {
          label: '',
          field: 'copy',
          width: '130px',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      rows: [],
    }
  },
  computed :{
    tag_dict (){
      return this.tags.reduce((r,e)=>{ r[e.id]=e.name; return r; }, {});
    },
    category_dict (){
      return this.categories.reduce((r,e)=>{ r[e.id]=e.name; return r; }, {});
    }
  },
  async mounted (){
    await this.get_categories();
    await this.get_tags();
    await this.get_articles();
  },
  methods: {
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get_categories (){
      try{
        let res = await this.axios.get("/api/blog/category");
        this.categories = res.data;
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async create_new_category (){
      let val = await this.$swal("","一度作成したカテゴリーは削除できません。続行しますか？", "warning", { dangerMode:true });
      if (!val)
        return;
      try{
        this.loading();
        let res = await this.axios.post("/api/blog/category", this.new_category);
        this.categories = res.data;
        this.new_category = { name:"", slug:"", description:"" };
        this.$swal.close();
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async get_tags (){
      try{
        let res = await this.axios.get("/api/blog/tag");
        this.tags = res.data;
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async create_new_tag (){
      let val = await this.$swal("","一度作成したタグは削除できません。続行しますか？", "warning", { dangerMode:true });
      if (!val)
        return;
      try{
        this.loading();
        let res = await this.axios.post("/api/blog/tag", this.new_tag);
        this.tags = res.data;
        this.new_tag = { name:"", slug:"", description:"" };
        this.$swal.close();
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async create_article (){
      try {
        let res = await this.axios.post("/api/blog/article", { new:true });
        this.$router.push({ path:"/blog/article", query:{ id:res.data.article_id } });
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    async get_articles (){
      try{
        let res = await this.axios.get("/api/blog/articles");
        this.articles = res.data;
        if (this.$swal.getState().isOpen)
          this.$swal.close();
      }
      catch(e){
        console.log(e);
        this.$swal("Error","","error");
      }
    },
    at_sortFn(x, y, col, rowX, rowY) {
      let _x = rowX.updated_at? rowX.updated_at:(rowX.published_at? rowX.published_at:0)
      let _y = rowY.updated_at? rowY.updated_at:(rowY.published_at? rowY.published_at:0)
      // x - row1 value for column
      // y - row2 value for column
      // col - column being sorted
      // rowX - row object for row1
      // rowY - row object for row2
      return (_x < _y ? -1 : (_x > _y ? 1 : 0));
    },
    rank_sortFn(x, y, col, rowX, rowY) {
      return (x.rank < y.rank ? -1 : (x.rank > y.rank ? 1 : 0));
    },
    async duplicate (id){
      this.loading()
      try {
        await this.axios.get("/api/blog/duplicate?id="+id);
        await this.get_articles();
      }
      catch(e){
        console.log({e});
        this.$swal("Error","","error");
      }
    },
    async delete_article (row){
      let val = await this.$swal(`記事の削除`,`記事タイトル\n『${row.title}』\n\nこの操作は取り消しできません。本当に削除しますか？`, { buttons:[true,true], dangerMode:true });
      if (!val)
        return;
      this.loading()
      try {
        await this.axios.post("/api/blog/del", { article_id:row.id });
        await this.get_articles();
      }
      catch(e){
        console.log({e});
        this.$swal("Error","","error");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.category-table, .tag-table {
  th, td {
    vertical-align: middle;
  }
  .id {
    width: 80px;
    text-align: center;
  }
}

.bi {
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -0.125em;
}

.category {
  font-size: 12px;
  white-space: nowrap;
  color: #3D5B78;
  font-weight: bold;
  padding: 0.1rem;
  border-radius: 3px;
  margin: 0 0.25rem;
}
.tag {
  font-size: 12px;
  border: solid 1px darkgray;
  white-space: nowrap;
  padding: 0.1rem;
  border-radius: 3px;
  margin: 0 0.25rem;
}
.articles ::v-deep {
  .vgt-table thead th {
    padding-right: .75em;
  }
  
  .vgt-table th.filter-th {
      padding: .75em .5em .75em .5em;
  }
  .vgt-input {
    padding: 6px 10px;
  }
  .vgt-table td, .vgt-table th {
    vertical-align: middle;
  }

  .feather {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
    margin-top: 1px;
    &:focus {
      outline: none!important;
    }
  }
}

.featured-button {
  border: none;
  background: transparent;
  color: #0d6efd;
  &:hover {
    color: #0a58ca;
  }
}
</style>
